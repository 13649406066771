import React from "react";
import styled from "@emotion/styled";
import {
  Card,
  CardContent,
  Box,
  Typography,
  Alert,
  TextField,
  Select,
  Modal,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import useWebservice from "@livepix/components/hooks/useWebservice";
import LoadingButton from "@mui/lab/LoadingButton";
import Captcha, { ReCAPTCHA } from "@livepix/components/common/Captcha";
import { isEmailValid } from "@livepix/sdk-js/validation";

const ModalCard = styled.div`
  max-width: 500px;
  margin: 50px auto 0;
  padding: 10px;
  border-radius: 10px;
  background: white;
`;

const Title = styled(Typography)`
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 24px;
`;

const Subtitle = styled(Typography)`
  margin-bottom: 30px;
  font-size: 16px;
  color: #666;
`;

type Props = {
  username: string;
  onClose: () => void;
};

const reasons: string[] = [
  "Terrorismo",
  "Fraude",
  "Exploração ou Abuso de Menores",
  "Assédio ou Discurso de Ódio",
  "Violação de Direitos Autorais",
  "Publicidade Enganosa ou Spam",
  "Violação de Privacidade",
  "Uso de Bots ou Manipulação de Doações",
];

export default function AddBillingAccountBalanceModal({ username, onClose }: Props) {
  const webservice = useWebservice();

  const [captcha, setCaptcha] = React.useState<ReCAPTCHA>();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [sent, setSent] = React.useState<boolean>(false);

  const [email, setEmail] = React.useState<string>("");
  const [reason, setReason] = React.useState<string>("");
  const [message, setMessage] = React.useState<string>("");

  const [errorMessage, setErrorMessage] = React.useState<string>();

  const sendComplaint = async () => {
    if (!captcha) {
      setErrorMessage("Não foi possível carregar o Captcha.");
      return;
    }

    if (!isEmailValid(email)) {
      setErrorMessage("Digite seu e-mail.");
      return;
    }

    if (!reason) {
      setErrorMessage("Selecione um motivo.");
      return;
    }

    if (!message) {
      setErrorMessage("Digite uma mensagem.");
      return;
    }

    setLoading(true);
    setErrorMessage(undefined);

    try {
      const captchaToken = await captcha.executeAsync();

      await webservice.post<{ url: string }>(
        `/profile/${username}/complaint`,
        {
          email,
          reason,
          message,
          captchaToken,
        },
        {
          timeout: 60_000,
        },
      );

      setSent(true);
    } catch (e: any) {
      setErrorMessage(e.response?.data?.message || "Não foi possível enviar a denúncia.");
    } finally {
      captcha.reset();
      setLoading(false);
    }
  };

  return (
    <Modal open onClose={onClose}>
      <ModalCard>
        <Captcha onReady={(instance) => setCaptcha(instance)} />
        {sent ? (
          <CardContent>
            <Title>Obrigado!</Title>
            <Subtitle>
              Sua denúncia foi enviada com sucesso. Nossa equipe de combate a fraude irá analisar o caso e tomar as
              medidas necessárias.
            </Subtitle>
            <Box marginTop="20px">
              <LoadingButton variant="outlined" size="large" onClick={onClose} fullWidth>
                Fechar
              </LoadingButton>
            </Box>
          </CardContent>
        ) : (
          <CardContent>
            <Title>Denúncia</Title>
            <Subtitle>
              Se tiver suspeitas de que este usuário está conduzindo atividades ilegais, envie uma denúncia para nossa
              equipe de combate a fraude.
            </Subtitle>
            <Box marginBottom="15px">
              <TextField
                label="Seu e-mail"
                helperText="Usaremos este e-mail para entrar em contato com você. Não compartilharemos seu e-mail com ninguém."
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
              />
            </Box>
            <Box marginBottom="15px">
              <FormControl fullWidth>
                <InputLabel>Motivo</InputLabel>
                <Select
                  variant="outlined"
                  label="Motivo"
                  value={reason}
                  onChange={(e) => setReason(e.target.value as string)}
                  fullWidth
                >
                  {reasons.map((reason) => (
                    <MenuItem key={reason} value={reason}>
                      {reason}
                    </MenuItem>
                  ))}
                  <MenuItem value="Outro">Outro</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box marginBottom="15px">
              <TextField
                label="Mensagem"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                rows={4}
                maxRows={Infinity}
                fullWidth
                multiline
              />
            </Box>
            {errorMessage && (
              <Box marginTop="20px">
                <Alert severity="error">{errorMessage}</Alert>
              </Box>
            )}
            <Box marginTop="20px">
              <LoadingButton
                variant="outlined"
                size="large"
                loading={loading}
                onClick={() => sendComplaint()}
                fullWidth
              >
                Enviar
              </LoadingButton>
            </Box>
          </CardContent>
        )}
      </ModalCard>
    </Modal>
  );
}
