import React from "react";
import moment from "moment";
import "moment/locale/pt-br";
import styled from "@emotion/styled";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import useWebservice from "@livepix/components/hooks/useWebservice";
import { Subscription } from "@livepix/sdk-js/types/core";
import { ProfileData } from "@livepix/sdk-js/types/profile";

import { Button, IconButton } from "@mui/material";

const Container = styled.div<{ width: string }>`
  position: relative;
  margin: 20px 0 0;
  width: ${(props) => props.width};

  @media (max-width: 440px) {
    width: 100%;
  }
`;

const SlidesContainer = styled.div`
  border-radius: 10px;
  overflow: hidden;
`;

const SlideContainer = styled.div<{ background: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100px;
  padding: 15px 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  background: ${(props) => props.background};
  color: #555;
`;

const Content = styled.div`
  flex: 1;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const Title = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.8);
  text-transform: uppercase;
  font-weight: bold;
`;

const RenewDate = styled.p`
  margin: 5px 0 0;
  font-size: 16px;
  line-height: 20px;
  color: white;
`;

const Amount = styled.p`
  margin: 0 0 10px;
  font-size: 26px;
  line-height: 30px;
  font-weight: bold;
  color: white;
`;

const AccountButton = styled(Button)`
  margin: 0;
  width: 100px;
  box-shadow: none;
  text-transform: initial;
  background: white;
  color: #000;

  &:hover {
    box-shadow: none;
    background: #f6f6f6;
  }
`;

const ArrowButton = styled(IconButton)<{ bgcolor: string }>`
  position: absolute;
  top: calc(50% - 12px);
  padding: 0;
  background-color: white;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  z-index: 1000;

  &:hover {
    background-color: black;
    color: white;
  }

  &:disabled {
    display: none;
  }
`;

const PreviousButton = styled(ArrowButton)`
  left: -12px;
`;

const NextButton = styled(ArrowButton)`
  right: -12px;
`;

const formatDate = (date: Date) => moment(date).locale("pt-br").format("LL");

const formatAmount = (amount: number) =>
  new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(amount);

type Props = {
  profile: ProfileData;
  width?: string;
};

export default function ProfileSubscriptions({ profile, width }: Props) {
  const webservice = useWebservice();

  const carouselRef = React.useRef<any>(null);

  const [carouselIndex, setCarouselIndex] = React.useState<number>(0);

  const { data: subscriptions } = webservice.load<Subscription[]>(`/profile/${profile.username}/subscriptions`);

  React.useEffect(() => {
    if (!subscriptions || subscriptions.length === 0) return;
    requestAnimationFrame(() => carouselRef.current?.updateHeight());
  }, [subscriptions]);

  if (!subscriptions || subscriptions.length === 0) return null;

  return (
    <Container width={width || "400px"}>
      <PreviousButton
        bgcolor={profile.color}
        disabled={carouselIndex <= 0}
        onClick={() => setCarouselIndex((current) => current - 1)}
      >
        <KeyboardArrowLeftIcon />
      </PreviousButton>
      <SlidesContainer>
        <SwipeableViews
          ref={carouselRef}
          color={profile.color}
          index={carouselIndex}
          onChangeIndex={(index: number) => setCarouselIndex(index)}
        >
          {subscriptions?.map((subscription) => (
            <SlideContainer key={subscription.id} background={profile.color}>
              <Content>
                <Title>{subscription.status === "Active" ? "Assinatura ativa" : "Assinatura expirada"}</Title>
                {subscription.status === "Active" && subscription.renewAt && (
                  <RenewDate>
                    Renovação em
                    <br />
                    {formatDate(subscription.renewAt)}
                  </RenewDate>
                )}
                {subscription.status === "Cancelled" && subscription.renewAt && (
                  <RenewDate>
                    Expirou em
                    <br />
                    {formatDate(subscription.renewAt)}
                  </RenewDate>
                )}
              </Content>
              <Details>
                <Amount>{formatAmount(subscription.amount)}</Amount>
                <AccountButton
                  href={`/${profile.username}/assinatura/${subscription.id}`}
                  variant="contained"
                  size="small"
                >
                  RENOVAR
                </AccountButton>
              </Details>
            </SlideContainer>
          ))}
        </SwipeableViews>
      </SlidesContainer>
      <NextButton
        bgcolor={profile.color}
        disabled={carouselIndex + 1 >= (subscriptions?.length || 0)}
        onClick={() => setCarouselIndex((current) => current + 1)}
      >
        <KeyboardArrowRightIcon />
      </NextButton>
    </Container>
  );
}
