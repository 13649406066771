import React, { MouseEventHandler } from "react";
import { useRouter } from "next/router";
import styled from "@emotion/styled";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { CircularProgress, Menu, MenuItem, Avatar, Button } from "@mui/material";
import useUser from "@livepix/components/hooks/useUser";
import useWebservice from "@livepix/components/hooks/useWebservice";
import useEndpoint from "@livepix/components/hooks/useEndpoint";
import { Realm } from "@livepix/sdk-js/types/core";

const Container = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px;
  height: 44px;
  background: white;
  color: black;
  border-radius: 30px;
  min-width: 0;

  svg.MuiSvgIcon-root {
    margin: 0 -4px;
    font-size: 44px;
    color: #b1b1b1;
  }

  > span {
    width: 36px !important;
    height: 36px !important;
  }

  &:hover {
    background: #14539a;
    color: white;

    svg {
      color: white;
    }
  }
`;

const Login = styled.p`
  padding: 0;
  margin: 0 10px 0 8px;
  font-size: 16px;
  text-transform: uppercase;

  @media (max-width: 500px) {
    display: none;
  }
`;

const UserName = styled.p`
  padding: 0;
  margin: 0 10px 3px 10px;
  font-size: 18px;
  line-height: 20px;
  text-transform: none;

  @media (max-width: 500px) {
    display: none;
  }
`;

export default function Account() {
  const router = useRouter();
  const endpoint = useEndpoint();
  const webservice = useWebservice();

  const { user, profile, isLoading } = useUser(false);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | undefined>(undefined);

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  if (isLoading) {
    return (
      <Container disabled>
        <CircularProgress />
      </Container>
    );
  }

  if (!user) {
    return (
      <Container href={endpoint.authUrl(`/login?redirect=${endpoint.appUrl(router.asPath)}`)}>
        <AccountCircleIcon />
        <Login>Acesse sua conta</Login>
      </Container>
    );
  }

  return (
    <>
      <Container onClick={handleClick}>
        <Avatar src={profile?.avatar || undefined} alt={user.username} sx={{ width: 36, height: 36 }} />
        <UserName>{user.username}</UserName>
      </Container>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted
      >
        {user.realm === Realm.ContentCreator && (
          <MenuItem component="a" href={endpoint.dashboardUrl("/")}>
            Dashboard
          </MenuItem>
        )}
        <MenuItem component="a" href={endpoint.accountUrl(`/?redirect=${endpoint.appUrl(router.asPath)}`)}>
          Minha Conta
        </MenuItem>
        <MenuItem style={{ width: "140px" }} component="a" href={webservice.buildUrl("/auth/logout")}>
          Sair
        </MenuItem>
      </Menu>
    </>
  );
}
