import React from "react";
import styled from "@emotion/styled";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Avatar, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { ProfileData } from "@livepix/sdk-js/types/profile";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Loading from "./Loading";
import ComplaintModal from "./ComplaintModal";

const Container = styled.div<{ width: string; color: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${(props) => props.width};
  margin-top: 60px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  background: white;
  position: relative;

  label.MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.color};
  }

  .Mui-focused fieldset {
    border-color: ${(props) => props.color} !important;
  }

  @media (max-width: 440px) {
    padding: 10px;
    width: 100%;
  }
`;

const ProfileMenuButton = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const AvatarContainer = styled.div`
  margin-top: -70px;
  margin-bottom: 10px;
  width: 100px;
  height: 100px;
  padding: 4px;
  background: white;
  border-radius: 50%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

  > div {
    width: 100%;
    height: 100%;
  }
`;

const DisplayName = styled.h1`
  margin: 0;
  font-weight: bold;
  font-size: 28px;

  svg {
    margin-bottom: -3px;
    font-size: 22px;
  }
`;

type Props = {
  profile: ProfileData;
  children: any;
  noUsername?: boolean;
  loading?: boolean;
  width?: string;
};

export default function Profile({ profile, children, noUsername, loading, width }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [complaintModalOpen, setComplaintModalOpen] = React.useState<boolean>(false);

  const menuOpen = Boolean(anchorEl);

  return (
    <>
      <Container width={width || "400px"} color={profile.color}>
        <ProfileMenuButton
          aria-controls={menuOpen ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={menuOpen ? "true" : undefined}
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          <MoreVertIcon />
        </ProfileMenuButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={menuOpen}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              setComplaintModalOpen(true);
            }}
          >
            Denunciar
          </MenuItem>
        </Menu>
        {complaintModalOpen && (
          <ComplaintModal username={profile.username} onClose={() => setComplaintModalOpen(false)} />
        )}
        <AvatarContainer>
          <Avatar src={profile.avatar || undefined} alt={profile.name} />
        </AvatarContainer>
        {!noUsername && (
          <DisplayName>
            {profile.name}{" "}
            {profile.verified && (
              <Tooltip title="Verificado" placement="right" arrow>
                <VerifiedIcon />
              </Tooltip>
            )}
          </DisplayName>
        )}
        {loading ? <Loading /> : children}
      </Container>
    </>
  );
}
